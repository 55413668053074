.MuiTypography-h5{
    font-weight: bolder !important;
    color:#D83F87 !important;
}

/* background-color: #D83F87 !important; */
.MuiButton-root{
    color: #fff;
}
.MuiButton-root:hover{
    background-color: #D83F87 !important;
    color: #fff;
}
.MuiAutocomplete-root{
    border: #2D256C !important;
}

.MuiCard-root{
    padding: 1% 1.5%!important; 
}

.MuiIcon-root{
    color:#D83F87 !important;
   }  

   
   
   /* below one is calender color icon for datepicker in appointments Customer support executive */

/* .css-i4bv87-MuiSvgIcon-root{
    color:#2D256C
} */
